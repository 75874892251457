const NUMBER_FIXED_TABS = 2;

const CategoryBadges = ({ categories }) => {
  return (
    <div className="category-badges loading-highlighted">
      {categories?.slice(0, NUMBER_FIXED_TABS)?.map((cat) => (
        <span className="category-badges__label" key={cat?.id}>
          {cat?.name}
        </span>
      ))}
      {categories?.length > NUMBER_FIXED_TABS && (
        <span className="category-badges__label">{`+${
          categories.length - NUMBER_FIXED_TABS
        }`}</span>
      )}
    </div>
  );
};

export default CategoryBadges;
