import { useContext } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/fr";
import Interaction from "./interactions";
import ConditionalLink from "./conditional-link";
import Image from "./image";
import LanguageContext from "../context/language";
import Translate from "./translate";
import imagePh from "../images/placeholder-image.png";
import localizedFormat from "dayjs/plugin/localizedFormat";
import CategoryBadges from "./category-badges";
dayjs.extend(localizedFormat);

const NewsCard = ({ news, showInteractions, isDragging, navBack = false, tealiumId }) => {
  const isSkeletonLoading = news?.init;
  const { labels, cmsLang } = useContext(LanguageContext);
  const { masterID, title, customPublicationDate, coverImage } = news;
  const date = customPublicationDate?.slice(0, -5);
  const categories = news?.categoryString?.map((cat, i) => ({
    id: i,
    name: labels?.[cat] || cat?.[0]?.toUpperCase() + cat?.slice(1)?.toLowerCase(),
  }));
  const detailLink = news?.businessHub?.[0]?.externalReference
    ? `/business-hubs/${news.businessHub[0].externalReference}/news-detail/${masterID}`
    : news?.regionHub?.[0]?.externalReference &&
      news?.regionHub?.[0]?.externalReference !== "corporate"
    ? `/regional-hubs/${news.regionHub[0].externalReference}/news-detail/${masterID}`
    : `/news/${masterID}`;

  const hubName =
    news?.newsType?.externalReference === "hub-news"
      ? (news?.regionHub?.[0] || news?.businessHub?.[0])?.externalReference
      : null;

  return (
    <article
      className={classNames("news-card", isSkeletonLoading && "loading-skeleton")}
      data-id={masterID}
    >
      <div className="news-card__content">
        <ConditionalLink
          to={detailLink}
          className="news-card__img loading-highlighted"
          onClick={(e) => isDragging && e.preventDefault()}
          disabled={isSkeletonLoading}
          state={{ navBack }}
          data-element-id={tealiumId}
          data-description={tealiumId ? "image" : undefined}
        >
          {!isSkeletonLoading && (
            <Image
              cropUrlTemplate={coverImage?.cropUrlTemplate || imagePh}
              crops={[
                {
                  crop: "landscape_ratio16x9",
                  sizes: [
                    {
                      breakpoint: 768,
                      maxWidth: "100vw",
                    },
                    {
                      breakpoint: 1230,
                      maxWidth: "50vw",
                    },
                    {
                      breakpoint: 1600,
                      maxWidth: "33vw",
                    },
                    {
                      maxWidth: "25vw",
                    },
                  ],
                },
              ]}
              alt={title}
            />
          )}
        </ConditionalLink>
        <div className="news-card__info">
          <span className="news-card__type loading-highlighted">
            {!!hubName
              ? labels?.[`HUB_${hubName.toUpperCase()}_NEWS`] ||
                `${hubName[0]?.toUpperCase()}${hubName.slice(1)?.toLowerCase()} news`
              : labels?.[news?.newsType?.externalReference?.toUpperCase()] ||
                `${news?.newsType?.externalReference?.[0]?.toUpperCase()}${news?.newsType?.externalReference
                  ?.slice(1)
                  ?.toLowerCase()}`}
          </span>
          <h2 className="news-card__title title-h5 loading-highlighted">
            <ConditionalLink
              to={detailLink}
              disabled={isSkeletonLoading}
              state={{ navBack }}
              data-element-id={tealiumId}
              data-description={tealiumId ? "text" : undefined}
            >
              <Translate text={title} />
            </ConditionalLink>
          </h2>
          <time
            className="news-card__date loading-highlighted"
            dateTime={date}
            itemProp="datePublished"
          >
            {date && dayjs(date).locale(cmsLang).format("LL")}
          </time>
          {categories?.length > 0 && <CategoryBadges categories={categories} />}
        </div>
      </div>
      {showInteractions && !news?.init && (
        <Interaction
          newsId={news?.masterID}
          likesCounter={news?.likesCount}
          commentsCounter={news?.commentsCount}
          isLiked={news?.liked}
          goToComments={true}
          basePath={detailLink}
        />
      )}
    </article>
  );
};

export default NewsCard;
