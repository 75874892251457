import Image from "./image";
import { useMemo } from "react";
import Translate from "./translate";
import imagePh from "../images/placeholder-image.png";
import ConditionalLink from "./conditional-link";
import classNames from "classnames";
import getHubName from "../utils/getHubName";
import tealiumWording from "../utils/tealiumWording";

const HubMiniCard = ({ hub, loading, labels }) => {
  const hubName = useMemo(
    () => hub?.target?.split("/")[hub?.target?.split("/")?.length - 1],
    [hub]
  );

  return (
    <ConditionalLink
      disabled={loading}
      to={hub.target}
      className={classNames("hub-minicard", loading && "loading-skeleton")}
      data-element-id={`home_banner_${tealiumWording(hubName)}_myHub`}
      data-description="image"
    >
      <Image
        className="hub-minicard__img loading-highlighted"
        cropUrlTemplate={hub?.coverImage?.cropUrlTemplate || (loading ? "" : imagePh)}
        crops={[
          {
            crop: "landscape_ratio16x9",
            sizes: [
              {
                maxWidth: "20vw",
              },
            ],
          },
        ]}
        alt={hub?.title}
      />
      <div className="hub-minicard__name title-h5 loading-highlighted">
        <Translate text={getHubName(hub, labels, true)} />
      </div>
    </ConditionalLink>
  );
};
export default HubMiniCard;
