import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";

const PAGE_SIZE = 12;
const STALE_TIME = 300000; //5 min = 5*60*1000

const useResourceFetch = ({
  body,
  pageSize = PAGE_SIZE,
  placeholderData = { pages: [{ data: placeholders(4), init: true }] },
  initialData,
  enabled = true,
  staleTime = STALE_TIME,
  onSuccess = () => 0,
  contentType = "news",
}) => {
  const { callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useInfiniteQuery({
    queryKey: [contentType, pageSize, locale, body],
    queryFn: ({ pageParam = 0 }) => {
      const params = new URLSearchParams(
        Object.entries({ pageNumber: pageParam, pageSize, locale })
      );
      return callApi(`/resource/${contentType}?${params?.toString()}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "*/*" },
        body: JSON.stringify(body),
      });
    },
    enabled,
    staleTime,
    placeholderData,
    initialData,
    getNextPageParam: (_lastPage, pages) => {
      return pages?.length;
    },
    select: (data) => {
      const list = data?.pages?.map((page) => page?.data)?.flat();
      const lastPage = data?.pages.slice(-1)?.[0];
      return { ...lastPage, data: list };
    },
    onSuccess,
    onError: (err) => {
      console.error(`Error loading ${contentType}`, err);
    },
  });
};

export default useResourceFetch;
