import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext } from "react";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import { mergeUrlSearchParams } from "../../utils/mergeUrlSearchParams";
import placeholders from "../../utils/placeholders";
import { getQueryStringParamsFromQuery } from "../useFilters";

const PAGE_SIZE = 12;
const STALE_TIME = 300000; //5 min = 5*60*1000

const usePolicies = ({
  my = false,
  query,
  pageSize = PAGE_SIZE,
  hub,
  term = "",
  placeholderData = { pages: [{ data: placeholders(4), init: true }] },
  initialData,
  enabled = true,
  staleTime = STALE_TIME,
  onSuccess = () => 0,
}) => {
  const { callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useInfiniteQuery({
    queryKey: [
      `policies-${my ? "my" : "all"}`,
      pageSize,
      locale,
      query,
      ...(hub?.tag ? [hub] : []),
      ...(term ? [term] : []),
    ],
    queryFn: ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        pageNumber: pageParam,
        pageSize: pageSize,
        locale,
        ...(hub?.tag && { hubContext: hub.tag }),
        ...(term ? { term } : {}),
      });
      const filterParams = getQueryStringParamsFromQuery(query, "policies");
      return callApi(
        `/policy/${my ? "my" : "all"}?${mergeUrlSearchParams(params, filterParams).toString()}`
      );
    },
    enabled,
    staleTime,
    placeholderData,
    initialData,
    getNextPageParam: (_lastPage, pages) => {
      return pages?.length;
    },
    select: (data) => {
      const list = data?.pages?.map((page) => page?.data)?.flat();
      const lastPage = data?.pages.slice(-1)?.[0];
      return { ...lastPage, data: list };
    },
    onSuccess,
    onError: (err) => {
      console.error(`Error loading policies & procedures`, err);
    },
  });
};

export default usePolicies;
