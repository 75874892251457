import classNames from "classnames";
import Photogallery from "./photogallery";
import VideoPlayer from "./video-player";
import InternalMenu from "./internal-menu";
import Content from "./content";
import Translate from "./translate";
import Documents from "./documents";

const ColdPageContent = ({ page, pageContent, loading, internalMenuOpen, setInternalMenuOpen }) => {
  return (
    <main className={classNames("page coldpage text-center", loading && "loading-skeleton")}>
      <div
        className={classNames(
          "page__header wrapper-small",
          !!page?.siblings?.length && "page__header--menu"
        )}
      >
        <h1 className="page__title wrapper-small loading-highlighted">
          <Translate text={page?.label || pageContent?.title} />
        </h1>
        {!!page?.siblings?.length && (
          <InternalMenu
            page={page}
            mobileMenu={internalMenuOpen}
            setMobileMenu={setInternalMenuOpen}
          />
        )}
      </div>
      <Content news={pageContent} className="text wrapper-small" />
      {pageContent?.photoGallery?.filter((item) => item?.cropUrlTemplate)?.length > 0 && (
        <Photogallery
          photogallery={pageContent.photoGallery?.filter((item) => item?.cropUrlTemplate)}
        />
      )}
      {pageContent?.video?.data?.uri && <VideoPlayer video={pageContent.video} />}
      {pageContent?.attachments?.length > 0 && (
        <Documents resourceId={pageContent.id} documents={pageContent.attachments} />
      )}
    </main>
  );
};

export default ColdPageContent;
